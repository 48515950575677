import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import {
  AiFillYoutube,
  AiFillFacebook,
  AiFillInstagram,
} from "react-icons/ai";
import { FaFacebookMessenger } from "react-icons/fa";
import Gallery from 'react-image-gallery';

const reelContext = require.context("../../../public/reels", false, /\.(mp4|mov|webm)$/);
const imageContext = require.context("../../../public/photos", false, /\.(webp|jpeg|jpg|png)$/);
const vidContext = require.context("../../../public/slideshows", false, /\.(mp4|mov|webm)$/);
const slideshowContext = require.context("../../../public/slideshows", false, /\.(mp4|mov|webm)$/);
const zipContext = require.context("../../../public", false, /\.(zip)$/);

const images = imageContext.keys().map((imagePath, index) => ({
  original: imageContext(imagePath),
  thumbnail: imageContext(imagePath),
  description: imagePath,
  loading: 'lazy',
  index: index + 1,
}));

const reels = reelContext.keys().map((reelPath, index) => ({
  src: reelContext(reelPath),
  index: index + 1,
}));

const vids = vidContext.keys().map((videoPath, index) => ({
  src: vidContext(videoPath),
  index: index + 1,
}));

const slideshows = slideshowContext.keys().map((slideshowPath, index) => ({
  src: slideshowContext(slideshowPath),
  index: index + 1,
}));

const handleVideoDownload = (src) => {
  const link = document.createElement("a");
  link.href = src;
  link.download = "content.mp4";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

const handleSlideshowDownload = (src) => {
  const link = document.createElement("a");
  link.href = src;
  link.download = "slideshow.mp4";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

const handlePhotoDownload = (src) => {
  const link = document.createElement("a");
  link.href = src;
  link.download = "photo.jpg"
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

const handleDownloadZip = (src) => {
  const link = document.createElement("a");
  link.href = src;
  link.download = "photos.zip";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <h1>Here Are Your <strong className="purple">Photos </strong></h1>
        <Gallery
          id='photos'
          items={images}
          showThumbnails={false}
          showIndex={true}
          showPlayButton={false}
          showFullscreenButton={false}
          autoPlay={false}
          renderItem={(item) => (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <img
                src={item.original}
                alt={item.description}
                className="rounded-image"
              />
            </div>
          )}
        />
        <Button className="download-btn" onClick={() => handleDownloadZip(zipContext.keys()[0])}>
          <h3>Download Zip File</h3>
        </Button>         
        {/* <Button className="download-btn" onClick={() => handlePhotoDownload(item.original)}>
          <h3>Download Photo {item.index}</h3>
        </Button> */}
        
        {/* <h1>Here Are Your <strong className="purple">GIFs</strong></h1>
        <Gallery
          id="vids"
          items={vids}
          showThumbnails={false}
          showIndex={false}
          showPlayButton={false}
          showFullscreenButton={false}
          showNav={true}
          renderItem={(item) => (
            <div className="video-container" key={item.index}>
              <video controls className="rounded-video">
                <source src={item.src} type="video/mp4" />
                Your browser does not support videos! Please contact us and let us know you got this error!
              </video>
              <Button className="download-btn" onClick={() => handleVideoDownload(item.src)}>
                <h3>Download GIF {item.index}</h3>
              </Button>
            </div>
          )}
        /> */}
        
        {/* <h1>Here Is Your <strong className="purple">Memory Slideshow</strong></h1>
        <Gallery
          id="slideshows"
          items={slideshows}
          showThumbnails={false}
          showIndex={false}
          showPlayButton={false}
          showFullscreenButton={false}
          showNav={true}
          renderItem={(item) => (
            <div className="video-container" key={item.index}>
              <video controls className="rounded-video">
                <source src={item.src} type="video/mp4" />
                Your browser does not support videos! Please contact us and let us know you got this error!
              </video>
              <Button className="download-btn" onClick={() => handleSlideshowDownload(item.src)}>
                <h3>Download Slideshow</h3>
              </Button>
            </div>
          )}
        /> */}
        
        <h1>Here Are Your <strong className="purple">Social Reels </strong></h1>
        <Gallery
          id="reels"
          items={reels}
          showThumbnails={false}
          showIndex={true}
          showPlayButton={false}
          showFullscreenButton={false}
          showNav={true}
          renderItem={(item) => (
            <div className="reel-container" key={item.index}>
              <video controls className="rounded-reel">
                <source src={item.src} type="video/mp4" />
                Your browser does not support videos! Please contact us and let us know you got this error!
              </video>
              <Button className="download-btn" onClick={() => handleVideoDownload(item.src)}>
                <h3>Download Social Reel {item.index}</h3>
              </Button>
            </div>
          )}
        />

        <Row>
          <Col md={12} className="home-about-social">
            {/* <h1>Need to Grow <strong className="purple">More?</strong></h1> */}
            <br></br>
            <h1>Text <strong className="purple">GROWTH</strong> to </h1>
            <h1><a href="tel:+19154007796" target="_blank" rel="noreferrer" className="phone-link">(915)400-7796</a></h1>
            <h1> for<strong className="purple"> 15% Off</strong> Your Next Shoot!</h1>
          </Col>
        </Row>

        <Row>
          <Col md={12} className="home-about-social">
            <h1>Connect With <strong className="purple">Us</strong> On</h1>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://www.youtube.com/@odaproductions2954"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillYoutube />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.facebook.com/odaproductions915"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillFacebook />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/odaproductions"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://m.me/odaproductions915"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <FaFacebookMessenger />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Home2;
